import HeadingsSettings from "./HeadingsSettings";
import FreeTextSettings from "./FreeTextSettings";
import ContactSetting from "./ContactSettings";
import EmailSettings from "./EmailSettings";
import InputFieldSettings from "./InputFieldSettings";
import NumberFieldSettings from "./NumberFieldSettings";
import TextAreaSettings from "./TextAreaSettings";
import CheckboxSettings from "./CheckboxSettings";
import DropDownSettings from "./DropDownSettings";
import RadioButtonSettings from "./RadioButtonSettings";
import DateSettings from "./DateSettings";
import TimeSettings from "./TimeSettings";
import StarRatingSettings from "./StarRatingSettings";

export const SideElements = [
  {
    id: "Headings",
    text: "Headings",
    type: "UIBuilder",
    ui: HeadingsSettings,
    icon: "gridicons:heading",
  },
  {
    id: "FreeText",
    text: "Free-Text",
    type: "UIBuilder",
    ui: FreeTextSettings,
    icon: "bi:textarea-t",
  },
  {
    id: "Email",
    text: "Email",
    type: "UIBuilder",
    ui: EmailSettings,
    icon: "carbon:email",
  },
  {
    id: "Contact",
    text: "Contact",
    type: "UIBuilder",
    ui: ContactSetting,
    icon: "carbon:phone",
  },
  {
    id: "InputField",
    text: "Input field",
    type: "UIBuilder",
    ui: InputFieldSettings,
    icon: "bi:input-cursor",
  },
  {
    id: "NumberField",
    text: "Number field",
    type: "UIBuilder",
    ui: NumberFieldSettings,
    icon: "bi:input-cursor",
  },
  {
    id: "TextArea",
    text: "Text Area",
    type: "UIBuilder",
    ui: TextAreaSettings,
    icon: "bi:textarea-resize",
  },
  {
    id: "DropDown",
    text: "Dropdown",
    type: "UIBuilder",
    ui: DropDownSettings,
    icon: "mdi:form-dropdown",
  },
  {
    id: "RadioButton",
    text: "Radio Button",
    type: "UIBuilder",
    ui: RadioButtonSettings,
    icon: "bx:radio-circle-marked",
  },
  {
    id: "CheckBox",
    text: "Checkbox",
    type: "UIBuilder",
    ui: CheckboxSettings,
    icon: "majesticons:checkbox-list-line",
  },
  {
    id: "Date",
    text: "Date",
    type: "UIBuilder",
    ui: DateSettings,
    icon: "bi:calendar2-date",
  },
  {
    id: "Time",
    text: "Time",
    type: "UIBuilder",
    ui: TimeSettings,
    icon: "carbon:time",
  },
  {
    id: "Ratings",
    text: "Ratings",
    type: "UIBuilder",
    ui: StarRatingSettings,
    icon: "carbon:star",
  },
];
