import React from "react";

import InterFaceLogo from "../assets/images/interface_analytics.png";

const SisuActivity = () => {
  const svgImg = () => {
    return (
      <svg
        width="70"
        height="20"
        viewBox="100 0 20 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M37.7157 23.4468H27.6261V47.9999H37.7157V23.4468Z"
            fill="#FF5000"
          />
          <path
            d="M37.7157 9.58765H27.6261V19.6773H37.7157V9.58765Z"
            fill="#FF5000"
          />
          <path
            d="M10.0896 9.58765H0V34.1408H10.0896V9.58765Z"
            fill="#FF5000"
          />
          <path
            d="M10.0896 37.9207H0V48.0103H10.0896V37.9207Z"
            fill="#FF5000"
          />
          <path
            d="M23.7951 9.58765H13.7055V26.3252H23.7951V9.58765Z"
            fill="#FF5000"
          />
          <path
            d="M23.7951 30.105H13.7055V48.0102H23.7951V30.105Z"
            fill="#FF5000"
          />
          <path
            d="M49.3316 42.1409L50.7247 40.3073C54.6373 43.4826 59.4949 45.2663 64.5326 45.3778C70.484 45.3778 75.1139 42.2228 75.1139 37.6646V37.5212C75.1139 32.3073 69.5313 30.6889 63.8463 29.1422C56.9424 27.155 50.991 25.2497 50.991 19.0833V18.9399C51.0217 13.4187 56.0922 9.21899 63.6517 9.21899C68.2892 9.30168 72.8037 10.7247 76.6504 13.3163L75.3393 15.365C71.9027 12.9171 67.8086 11.5608 63.5902 11.4725C57.4443 11.4725 53.4494 14.6274 53.4494 18.7452V18.8886C53.4494 23.5186 58.6632 25.2088 64.717 26.8989C71.4468 28.6812 77.5416 30.8323 77.5416 37.2958V37.4392C77.5416 43.5852 71.8156 47.5801 64.3892 47.5801C58.901 47.513 53.5958 45.5966 49.3316 42.1409"
            fill="#FF5000"
          />
          <path
            d="M83.9334 0H87.0678V4.47631H83.9334V0ZM84.3022 9.89501H86.7298V47.8771H84.3022V9.89501Z"
            fill="#FF5000"
          />
          <path
            d="M92.1895 42.1409L93.5825 40.3073C97.4952 43.4826 102.353 45.2663 107.39 45.3778C113.342 45.3778 117.972 42.2228 117.972 37.6646V37.5212C117.972 32.3073 112.389 30.6889 106.704 29.1422C99.8002 27.155 93.8489 25.2497 93.8489 19.0833V18.9399C93.9206 13.4187 98.991 9.21899 106.53 9.21899C111.168 9.30109 115.682 10.7242 119.529 13.3163L118.238 15.365C114.817 12.9134 110.738 11.5466 106.53 11.4418C100.384 11.4418 96.3892 14.5967 96.3892 18.7145V18.8579C96.3892 23.4879 101.603 25.178 107.657 26.8682C114.417 28.7017 120.512 30.8323 120.512 37.2958V37.4392C120.512 43.5852 114.786 47.5801 107.36 47.5801C101.833 47.5384 96.4838 45.6205 92.1895 42.1409"
            fill="#FF5000"
          />
          <path
            d="M125.992 31.5698V9.89502H128.338V31.201C128.338 39.7951 132.968 45.5928 141.562 45.5928C149.347 45.5928 155.513 39.201 155.513 30.7503V9.89502H157.859V47.8771H155.513V38.105C154.419 40.9865 152.465 43.4618 149.916 45.1952C147.367 46.9286 144.347 47.8363 141.265 47.7951C131.493 47.7951 125.992 41.1882 125.992 31.5595"
            fill="#FF5000"
          />
          <path
            d="M161.967 11.4418H160.461V10.4175H164.63V11.4418H163.124V15.4777H161.977L161.967 11.4418ZM165.685 10.4175H166.894L168.225 12.5481L169.557 10.4175H170.735V15.4674H169.649V12.0666L168.256 14.2792H168.154L166.761 12.0666V15.4777H165.675L165.685 10.4175Z"
            fill="#FF5000"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="170.745" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return (
    <>
      {/* <div className=" mb-4 pointer"> */}
        <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
         
        <img className="me-2 p-0 fs-5" height="20" src={InterFaceLogo} alt="" />
          <span>InterFace Analytics</span>

          <span className="ms-auto rounded-pill border border-warning fs-small px-2 py-1 alert-warning fw-bold">
            soon
          </span>
        </div>
      {/* </div> */}
    </>
  );
};

export default SisuActivity;
