import React, { useContext } from "react";
import AccordionReusable from "./AccordionReusable";
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";

const AccordionBasic = ({
  keys,
  set_insert_value,
  field_id,
  select_modal,
 
  acccordion_title,
}) => {
  const { isModalOpen, setIsModalOpen } = useContext(WorkFlowSessionInfo);
  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
    select_modal("");
  }
  const style=isModalOpen ? {position: "absolute", maxWidth: "100%",display: "none"} : {position: "absolute", maxWidth: "100%", }
 
  return (
    <div className="collapse w-100 px-0 pb-1 bg-white col-12 f-drop border shadow-lg" id={"modal_" + field_id} style={style}>
      <div className="w-100 p-3 d-flex align-items-center justify-content-between bg-color-1 border-bottom">
        <h5 className="fs-6 m-0">Select Data</h5>
        <div onClick={(e) =>handleClick()}>
          <i className="pointer iconify fs-5" data-icon="mdi:close"></i>
        </div>
      </div>
      
      {/* <Accordion defaultActiveKey="0"> */}
        <AccordionReusable
          keys={keys}
          set_insert_value={set_insert_value}
          acccordion_title={acccordion_title}
        />
      {/* </Accordion> */}
      {/* <AccordionDefaultOptions
        keys={keys}
        set_insert_value={set_insert_value}
        field_id={field_id}
        select_modal={select_modal}
        acccordion_title={acccordion_title}
      /> */}
    </div>
  );
};

export default AccordionBasic;
