import CustomArray from "../../components/fields/CustomArray";
const AddTag = ({
  action_id,
  select_modal,
  selected_modal_id,
  update_data_mapper,
  UpdateAction,
  data_mapper,
}) => {

  return (
    <CustomArray
      action_id={action_id}
      select_modal={select_modal}
      selected_modal_id={selected_modal_id}
      update_data_mapper={update_data_mapper}
      UpdateAction={UpdateAction}
      data_mapper={data_mapper}
    />
  );
};

export default AddTag;
