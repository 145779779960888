import { ContactUi } from "./form-builder-components/BoardElementsUI/ContactUi";
import { HeadingUi } from "./form-builder-components/BoardElementsUI/HeadingUi";
import { FreeTextUi } from "./form-builder-components/BoardElementsUI/FreeTextUi";
import { EmailUi } from "./form-builder-components/BoardElementsUI/EmailUi";
import { InputUi } from "./form-builder-components/BoardElementsUI/InputUi";
import { NumberFieldUi } from "./form-builder-components/BoardElementsUI/NumberFieldUi"
import { TextareaUi } from "./form-builder-components/BoardElementsUI/TextareaUi";
import { RadiobuttonUi } from "./form-builder-components/BoardElementsUI/RadiobuttonUi";
import { DropDownUi } from "./form-builder-components/BoardElementsUI/DropDownUi";
import { TimeUi } from "./form-builder-components/BoardElementsUI/TimeUi";
import { DateUi } from "./form-builder-components/BoardElementsUI/DateUi";
import { CheckBoxUi } from "./form-builder-components/BoardElementsUI/CheckBoxUi";
import { StarRatingUi } from "./form-builder-components/BoardElementsUI/StarRatingUi";

export const Elements = [
  {
    id: "Headings",
    text: "Headings",
    type: "UIBuilder",
    ui:HeadingUi,
    icon: "gridicons:heading"
  },
  {
    id: "FreeText",
    text: "FreeText",
    type: "UIBuilder",
    ui:FreeTextUi ,
    icon: "bi:textarea-t"
  },
  {
    id: "Email",
    text: "Email",
    type: "UIBuilder",
    ui:EmailUi,
    icon: "carbon:email"
  },
  {
    id: "Contact",
    text: "Contact",
    type: "UIBuilder",
    ui: ContactUi,
    icon: "carbon:phone"
  },
  {
    id: "InputField",
    text: "Input field",
    type: "UIBuilder",
    ui: InputUi,
    icon: "bi:input-cursor"
  },
  {
    id: "NumberField",
    text: "Number field",
    type: "UIBuilder",
    ui:NumberFieldUi,
    icon: "bi:input-cursor"
  },
  {
    id: "TextArea",
    text: "Text Area",
    type: "UIBuilder",
    ui: TextareaUi,
    icon: "bi:textarea-resize"
  },
  {
    id: "DropDown",
    text: "Dropdown",
    type: "UIBuilder",
    ui: DropDownUi,
    icon: "mdi:form-dropdown"
  },
  {
    id: "RadioButton",
    text: "Radio Button",
    type: "UIBuilder",
    ui: RadiobuttonUi,
    icon: "bx:radio-circle-marked"
  },
  {
    id: "CheckBox",
    text: "Checkbox",
    type: "UIBuilder",
    ui:CheckBoxUi,
    icon: "majesticons:checkbox-list-line"
  },
  {
    id: "Date",
    text: "Date",
    type: "UIBuilder",
    ui: DateUi,
    icon: "bi:calendar2-date"
  },
  {
    id: "Time",
    text: "Time",
    type: "UIBuilder",
    ui: TimeUi,
    icon: "carbon:time"
  },
  {
    id: "Ratings",
    text: "Ratings",
    type: "UIBuilder",
    ui: StarRatingUi,
    icon: "carbon:star"
  },
 
];

export default Elements;
