import React from "react";
import TwilioLogo from "../assets/images/twilio-logo.png";

const AwsRedisDatabase = () => {
  return (
    <>
    
        <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
        <img className="me-2 p-0 fs-5" height="20" src={TwilioLogo} alt="TwilioLogo" />
          <span>Twilio Notification</span>
          <span className="ms-auto rounded-pill border border-warning fs-small px-2 py-1 alert-warning fw-bold">
            soon
          </span>
        </div>
    </>
  );
};

export default AwsRedisDatabase;
