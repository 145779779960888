import React from "react";

const EmailNotification = () => {
  return (
    <>
        <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
          <i
            className="iconify me-2 p-0 fs-5 text-primary"
            data-icon="ic:outline-mark-email-unread"
          ></i>
          <span>Email Notification</span>
          <span className="ms-auto rounded-pill border border-warning fs-small px-2 py-1 alert-warning fw-bold">
            soon
          </span>
        </div>
    </>
  );
};

export default EmailNotification;
