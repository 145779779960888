import React from 'react'

function ConditionsEmpty() {
  return (

        <div className="w-100 text-center my-3">
          <i className="iconify display-1" data-icon="carbon:branch" style={{fontSize: '200px'}} />
          <p className="text-center mt-3">You haven’t added any conditions yet. <br />To learn more about adding conditions to forms <a className="text-primary text-decoration-underline">click here</a></p>
        </div>
  )
}

export default ConditionsEmpty