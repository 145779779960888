import React from 'react'
import { HeadNavBar } from './HeadNavBar'
import { Outlet } from 'react-router-dom'

export const LayoutNavbar = () => {
  return (
    <div>
        <HeadNavBar/>
        <Outlet/>
    </div>
  )
}
