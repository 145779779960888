import React from "react";
import FubLogo from "../assets/images/fub.png";

const FubAutomation = () => {

  return (
    <>
      {/* <div className=" mb-4 pointer"> */}
        <div className="d-flex align-items-center card-g bg-white p-3 border rounded-3">
          <img className="me-2 p-0 fs-5" height="20" src={FubLogo} alt="" />
          <span>Follow Up Boss</span>
          <i
            className="iconify text-secondary ms-auto"
            data-icon="mdi:info-circle"
          ></i>
        </div>
      {/* </div> */}
    
    </>
  );
};

export default FubAutomation;
